import React from 'react'
import styled from 'styled-components'
import Link from '../../Link'

const StyledBtnLink = styled.span`
	${props => (props.variant === 'white' ? 'color: #333333;' : 'color: #fff;')};
	${props =>
		props.variant === 'primary'
			? 'border: thick solid #fff;'
			: 'border: thick solid #F68F35;'};
	border-top: none;
	border-right: none;
	border-left: none;
	border-radius: 0;
	text-decoration: none;
	padding: 6px 1px 4px 1px;
	text-transform: uppercase;
	&:not(:last-child) {
		margin-right: 21px;
	}
`
const StyledButton = ({ variant, href, cta, useButton }) => {
	return (
		<>
			{useButton ? (
				<StyledBtnLink className="btn btn-link" variant={variant}>
					{cta}
				</StyledBtnLink>
			) : (
				<Link to={href || ''}>
					<StyledBtnLink className="btn btn-link" variant={variant}>
						{cta}
					</StyledBtnLink>
				</Link>
			)}
		</>
	)
}

export default StyledButton
